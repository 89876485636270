<template>
  <div class="or-event-card">
    <div class="wrap-photo-info">
      <div class="time-photo">
        <div class="time">
          <span>{{ detectTime }}</span> 
        </div>
        <div class="photo" @click="showEventDetail">
          <img v-lazy="event.snapshotUrl" alt="">
        </div>
      </div>
      <div class="info">
        <div class="icons-index">
          <div class="icons">
            <div v-if="event.motion === 1" class="icon moving">
              <img src="@/assets/icons/Moving.svg" alt="">
            </div>
            <div v-for="(cls, index) in objClass" :key="index" class="icon">
              <img :src="iconMap[icons[cls - 1]]" alt="">
            </div>
          </div>
          <div>
            <TooltipElement :content="String(eventNo)">
              <div class="index">{{ eventNo }}</div>
            </TooltipElement>
          </div>
        </div>
        <div class="content">
          <div class="items">
            
            <div class="item video-title">
              <TooltipElement :content="$t('history_fr_device'/*設備*/)" alwaysShow>
                <img src="@/assets/icons/Camera.svg" alt="">
              </TooltipElement>
              <TooltipElement :content="getDeviceTitleId(event.user.id)">
                <span>{{ getDeviceTitleId(event.user.id) }}</span>
              </TooltipElement>
            </div>

            <div class="item push-content">
              <TooltipElement :content="$t('history_fr_remark'/*備註*/)" alwaysShow>
                <img src="@/assets/icons/note.svg" alt="">
              </TooltipElement >
              <TooltipElement :content="event.notice">
                <span>{{ event.notice }}</span>
              </TooltipElement>
            </div>
          </div>
          <div v-if="event.latitude > 0 && event.longitude > 0" class="icons">
            <TooltipElement :content="$t('history_fr_open_google_map'/*開啟Google map*/)" alwaysShow>
              <img src="@/assets/icons/google-map-icon.png" alt="" @click="goGoogleMap(event.latitude, event.longitude)">
            </TooltipElement>
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <img v-if="event.latitude === 0 || event.longitude === 0" src="@/assets/icons/no-gps.svg" alt="">
      <EventMap v-else :event="event" type="or" :centerShift="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { getScore, getAge, formatTime } from '@/utils/lib.js'
import { 
  getGenderIcon, 
  getMisjudgedIcon, 
  getTagNameStr, 
  getTagNameList,
  goGoogleMap 
} from '@/utils/index.js'

export default {
  name: 'OrEventCard',
  components: {
    EventMap: () => import('@/components/Base/EventMap.vue'),
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
  },
  props: ['event', 'index'],
  data() {
    return {
      isShowTooltip: false,
      iconMap: {
        person: require('@/assets/icons/person.svg'),
        car: require('@/assets/icons/car.svg'),
        bicycle: require('@/assets/icons/bicycle.svg'),
        truck: require('@/assets/icons/truck.svg'),
        bus: require('@/assets/icons/bus.svg'),
      },
      icons: ['person', 'car', 'bicycle', 'truck', 'bus'],
    }
  },
  computed: {
    ...mapState(['frTagList']),
    ...mapState('historyOr', ['orCurrentPage', 'orPageSize']),
    ...mapGetters('account', ['getDeviceTitleId']),
    eventNo() {
      return (this.orCurrentPage - 1) * this.orPageSize + this.index + 1
    },
    detectTime() {
      return formatTime(this.event.detectTime)
    },
    objClass() {
      return this.event.objects.map(obj => obj.class).filter((v, i, a) => a.indexOf(v) === i)
    },
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalShow']),
    ...mapMutations('historyOr', ['updateOrCurrentIndex']),
    getScore,
    getAge,
    getGenderIcon,
    getMisjudgedIcon,
    getTagNameStr,
    getTagNameList,
    goGoogleMap,
    formatTime,
    showEventDetail() {
      this.updateOrCurrentIndex(this.index)
      this.$store.dispatch('historyOr/getObjsClassCount', this.event.objects)
      this.$store.dispatch('historyOr/getDetectAreas', this.event.metadata)
      this.updateHistoryEventModalShow(true)
    },
  }
}
</script>

<style lang="scss" scoped>
.or-event-card {
  width: 100%;
  height: 100%;
  background: #4A5C7880;
  border-radius: 8px;
  display: flex;
  // justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &:hover {
    background: #4A5C78cc;
  }
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 100%;
    background: #FFC600;
    left: 0;
    top: 0;
    border-radius: 8px 0 0 8px;
  }

  &.match::before {
    background: #FFC600;
  }
}

.wrap-photo-info {
  flex: 1;
  display: flex;
  column-gap: 12px;
  padding: 12px 14px 12px 32px;
}

.time-photo {
  width: px2rem(210);
  margin-right: 12px;
  .time {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  .time span {
    color: #FFFFFF;
    background: #282942;
    border-radius: 17px;
    padding: 5px 24px;
    @include font_style(px2rem(14), px2rem(21))
  }

  .photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #ffffff1a;
    border-radius: 3px;
    cursor: pointer;
    img {
      width: 100%;
      height: px2rem(128);
      object-fit: contain;
    }

    img[lazy=error] {
      width: 60%;
      height: 60%;
    }
    
    span {
      display: block;
      color: #FFFFFF80;
    }
  }
}

.info {
  flex: 1;
  // padding: 12px 20px 12px 12px;
  box-sizing: border-box;
  .icons-index {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    column-gap: 18px;
    .icons {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      font-size: px2rem(24);
      line-height: 40px;
      font-weight: 500;
      color: #FFE99F;

      .icon {
        width: 32px;
        height: 32px;
        &.moving {
          width: 48px;
          height: 32px;
        }
      }
      
      img {
        width: 100%;
        height: 100%;
        @include filter_FFE99F;
      }
      span {
        font-size: px2rem(24);
        font-weight: 500;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    .index {
      min-width: 41px;
      max-width: 116px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: px2rem(14);
      text-align: center;
      line-height: 25px;
      font-weight: normal;
      color: #282942;
      background: #FFE99F;
      padding: 0 8px;
      border-radius: 17px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .content {
    height: calc(100% - 45px);
    display: flex;
    column-gap: 20px;
    
    .items {
      width: calc(100% - 50px);
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        &.push-content {
          align-items: flex-start;
        }
        img {
          width: px2rem(20);
          height: px2rem(20);
          margin-right: 8px;
        }
      }
    }
    .icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      img {
        width: px2rem(20);
        height: px2rem(20);
        cursor: pointer;
      }
    }
  }
}

.tags {
  overflow: hidden;
  .wrap-tags {
    width: 100%;
    height: 35px;
    display: flex;
    span {
      background: #ffffff33;
      font-size: px2rem(20);
      padding: 0 12px;
      margin-right: 8px;
      border-radius: 18px;
      white-space: nowrap;
    }
  }
}

.video-title {
  width: 100%;
  display: flex;
  span {
    @include font_style(px2rem(14), px2rem(21));
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.push-content { 
  span {
    @include font_style(px2rem(14), px2rem(21));
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.map {
  width: 34%;
  min-width: px2rem(400);
  height: 100%;
  background: #4A5C7880;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20%;
  }
}
</style>